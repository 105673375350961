import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { API_URL } from '@/config'

const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },

  setAuthHeader (token) {
    Vue.axios.defaults.headers.common.Authorization = `Token ${token}`
  },

  destroyAuthHeader () {
    Vue.axios.defaults.headers.common.Authorization = null
  },

  get (resource, params, headers) {
    return Vue.axios.get(resource, { params: params, headers: headers })
  },

  post (resource, data, headers) {
    return Vue.axios.post(resource, data, { headers: headers })
  },

  patch (resource, data) {
    return Vue.axios.patch(resource, data)
  },

  put (resource, data) {
    return Vue.axios.put(resource, data)
  },

  delete (resource) {
    return Vue.axios.delete(resource)
  }
}

export default ApiService
