import ApiService from '@/services/api.service'
import utils from '@/utils'

const namespaced = true

const state = {
  events: [],
  eventsStateToken: null,
  eventsLoading: false,
  eventsCursor: null,

  summary: null,
  summaryStateToken: null,

  categoriesByOwner: null
}

const getters = {
  getPage (state) {
    return state.events
  },
  hasMoreEvents (state) {
    return state.eventsCursor !== null
  },
  getSummary (state) {
    return state.summary
  },
  getCategoriesByOwner (state) {
    return (ownerId) => {
      if (state.categoriesByOwner !== null) {
        return state.categoriesByOwner[ownerId]
      }
      return null
    }
  },
  getEventsLoading (state) {
    return state.eventsLoading
  }
}

const actions = {
  fetch (context, data) {
    const payload = {}
    if (data.filter !== undefined) {
      payload.filter = data.filter
    }
    if (data.owners !== undefined && data.owners.length > 0) {
      payload.owners = data.owners
    }

    context.commit('updateEventsStateToken', utils.uuidv4())
    context.commit('updateEventsLoading', true)

    ApiService.post('/events', payload, { 'X-Idempotency-Token': context.state.eventsStateToken })
      .then(response => {
        if (response.headers['x-idempotency-token'] === context.state.eventsStateToken) {
          context.commit('updatePaged', { append: false, data: response.data })
        }
      })
      .finally(() => {
        context.commit('updateEventsLoading', false)
      })
  },
  fetchNext (context) {
    if (context.state.eventsCursor === null) {
      return
    }

    const payload = { cursor: context.state.eventsCursor }

    context.commit('updateEventsStateToken', utils.uuidv4())
    context.commit('updateEventsLoading', true)

    ApiService.post('/events', payload, { 'X-Idempotency-Token': context.state.eventsStateToken })
      .then(response => {
        if (response.headers['x-idempotency-token'] === context.state.eventsStateToken) {
          context.commit('updatePaged', { append: true, data: response.data })
        }
      })
      .finally(() => {
        context.commit('updateEventsLoading', false)
      })
  },
  setCategory (context, data) {
    const oldEventsCategories = data.reduce((map, item) => {
      map[item.event.id] = item.oldCategory
      return map
    }, {})

    const requestData = {
      events: data.map(item => {
        context.commit('setCategory', {
          eventId: item.event.id,
          category: item.newCategory
        })
        return {
          event_id: item.event.id,
          event_version: item.event.version,
          category_id: item.newCategory.id
        }
      })
    }

    return new Promise((resolve, reject) => {
      ApiService.post('events/set-category', requestData)
        .then(response => {
          context.commit('updateEvents', response.data.updated_events)
          resolve()
        })
        .catch((err) => {
          for (const eventId in oldEventsCategories) {
            context.commit('setCategory', {
              eventId: eventId,
              category: oldEventsCategories[eventId]
            })
          }
          reject(err)
        })
    })
  },
  fetchSummary (context, data) {
    const url = '/events/summary'
    const payload = { filter: data.filter }
    if (data.owners.length > 0) {
      payload.owners = data.owners
    }

    context.commit('updateSummaryStateToken', utils.uuidv4())

    ApiService.post(url, payload, { 'X-Idempotency-Token': context.state.summaryStateToken })
      .then(response => {
        if (response.headers['x-idempotency-token'] === context.state.summaryStateToken) {
          context.commit('updateSummary', response.data)
        }
      })
  }
}

const mutations = {
  updatePaged (state, { append, data }) {
    state.eventsCursor = data.cursor
    if (append) {
      state.events.push(...data.results)
    } else {
      state.events = data.results
    }
    if (data.categories_by_owner) {
      state.categoriesByOwner = data.categories_by_owner
    }
  },
  setCategory (state, { eventId, category }) {
    const idx = state.events.findIndex(event => event.id === eventId)
    if (idx > -1) {
      if (category !== null) {
        state.events[idx].category = {
          id: category.id,
          title: category.title
        }
      } else {
        state.events[idx].category = null
      }
    }
  },
  updateEvents (state, updatedEvents) {
    const updatedEventsById = updatedEvents.reduce((map, event) => {
      map[event.id] = event
      return map
    }, {})

    state.events = state.events.map(
      event => updatedEventsById[event.id] === undefined
        ? event
        : updatedEventsById[event.id])
  },
  updateSummary (state, updatedSummary) {
    state.summary = updatedSummary
  },
  updateEventsStateToken (state, token) {
    state.eventsStateToken = token
  },
  updateSummaryStateToken (state, token) {
    state.summaryStateToken = token
  },
  updateEventsLoading (state, isLoading) {
    state.eventsLoading = isLoading
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
